<ui-top-bar name="Organization Detail">
  <ui-breadcrumbs [breadcrumbs]="breadcrumbs"></ui-breadcrumbs>
</ui-top-bar>

<ui-row>
  <ui-column width="narrow">
    <ui-card *ngIf="state === 'success' && organization" name="Organization Summary">
      <ui-field name="name">
        <ui-label>Name</ui-label>
        {{ organization.name }}
      </ui-field>
      <ui-field name="seats">
        <ui-label>Seats</ui-label>
        {{ organization.numberOfSeats }}
      </ui-field>
      <ui-field name="subscription">
        <ui-label>Subscription</ui-label>
        {{ organization.subscription }}
      </ui-field>
    </ui-card>
  </ui-column>

  <ui-column>
    <wa-pending-invites-card
      #pendingInvitesCard
      (invite)="onInviteMember()"
      *ngIf="organization"
      [organizationId]="organization.id"
    ></wa-pending-invites-card>
  </ui-column>
</ui-row>

<ui-row>
  <ui-column>
    <ui-card name="Members">
      User filtered by organization
      <br />
      endpoint: organizations/:id/users
      <br />
      Name - Role(s?) - Accepted - picture
    </ui-card>
  </ui-column>
</ui-row>

<wa-invite-modal
  (closeModal)="onCloseInviteModal()"
  (userInvited)="onUserInvited()"
  *ngIf="organization && showInviteModal"
  [organizationId]="organization.id"
></wa-invite-modal>
