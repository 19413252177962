<ui-card name="Login">
  <ui-form (submitForm)="onLogin()" [formGroup]="form">
    <ui-field name="email">
      <ui-label>Email</ui-label>
      <ui-text-input formControlName="email" placeholder="Email" type="email">
        <ng-container slot="error">Email is required</ng-container>
      </ui-text-input>
    </ui-field>

    <ui-field name="password">
      <ui-label>Password</ui-label>
      <ui-password-input formControlName="password" placeholder="Password">
        <ng-container slot="error">Password is required</ng-container>
      </ui-password-input>
    </ui-field>
  </ui-form>

  <ui-button (click)="onLogin()" (keydown.enter)="onLogin()" [fullWidth]="true" slot="footer" type="dark">
    Login now
  </ui-button>
</ui-card>
