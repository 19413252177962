<ui-top-bar [isFullWidth]="true" name="Organizations">
  <ui-breadcrumbs [breadcrumbs]="breadcrumbs"></ui-breadcrumbs>
  <ui-button (click)="onAddOrganization()" (keydown.enter)="onAddOrganization()" icon="plus" tabindex="0">
    Add Organization
  </ui-button>
</ui-top-bar>

<ui-data-table
  (request)="onRequest($event)"
  (rowClick)="onSelectOrganization($event)"
  [columns]="columns"
  [response]="response"
></ui-data-table>

<wa-add-organization-modal
  (closeModal)="onCloseAddOrganizationModal()"
  (organizationAdded)="onOrganizationAdded()"
  *ngIf="showAddOrganizationModal"
></wa-add-organization-modal>
