import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UiButton, UiCard, UiField, UiForm, UiLabel, UiPasswordInput, UiTextInput } from '@standardkit/ui';
import { AuthenticationService } from '@werfy/data/authentication';

@Component({
  imports: [UiButton, UiForm, ReactiveFormsModule, UiCard, UiField, UiLabel, UiTextInput, UiPasswordInput],
  templateUrl: 'login.page.html',
})
export class LoginPage {
  public form: FormGroup = new FormGroup({
    email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
    password: new FormControl<string | null>(null, [Validators.required]),
  });

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {}

  public onLogin(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.authenticationService.login$(this.form.value).subscribe((): void => {
      this.router.navigate(['/'], { onSameUrlNavigation: 'reload' });
    });
  }
}
