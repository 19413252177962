import { PaginationState, PaginationStateInterface } from '@standardkit/core';

export function mapLegacyPagination(pagination: PaginationState): PaginationStateInterface {
  return {
    offset: pagination.offset,
    limit: pagination.limit,
    numberOfRows: pagination.currentItems,
    totalNumberOfRows: pagination.totalItems,
    currentPage: pagination.currentPage,
    totalNumberOfPages: pagination.totalPages,
  };
}
