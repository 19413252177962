import { Routes } from '@angular/router';
import { OrganizationPage, OrganizationsPage } from '@werfy/pages';
import { LoggedInGuard, NotLoggedInGuard } from './guards';
import { CardLayout, SidebarLayout } from './layouts';
import { DashboardPage, LoginPage } from './pages';

export const routes: Routes = [
  { path: '', component: CardLayout, canMatch: [NotLoggedInGuard], children: [{ path: '', component: LoginPage }] },
  {
    path: '',
    component: SidebarLayout,
    canMatch: [LoggedInGuard],
    children: [
      { path: '', component: DashboardPage },
      { path: 'organizations', component: OrganizationsPage },
      { path: 'organizations/:id', component: OrganizationPage },
    ],
  },
];
