import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import {
  NavigationItemInterface,
  UiHeader,
  UiSidebar,
  UiSidebarPage,
  UiUserMenu,
  UserMenuItemInterface,
} from '@standardkit/ui';
import { AuthenticationService } from '@werfy/data/authentication';

@Component({
  imports: [RouterOutlet, UiSidebar, UiHeader, UiSidebarPage, UiUserMenu],
  templateUrl: 'sidebar.layout.html',
})
export class SidebarLayout {
  public navigationItems: NavigationItemInterface[] = [
    { icon: 'house', name: 'Dashboard', route: '', isExact: true },
    { name: 'Organizations', icon: 'building', route: 'organizations' },
  ];

  public menuItems: UserMenuItemInterface[] = [{ icon: 'power', name: 'Log out', action: () => this.logout() }];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {}

  private logout(): void {
    this.authenticationService.logout$().subscribe(() => {
      this.router.navigate(['/'], { onSameUrlNavigation: 'reload' });
    });
  }
}
