"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapDataRequestParameters = mapDataRequestParameters;
const filter_mapper_1 = require("./filter.mapper");
const pagination_mapper_1 = require("./pagination.mapper");
const sort_mapper_1 = require("./sort.mapper");
function mapDataRequestParameters(request) {
  const pagination = (0, pagination_mapper_1.mapPaginationParameters)(request.offset, request.limit);
  const sort = (0, sort_mapper_1.mapSortParameters)(request.sort);
  const filters = (0, filter_mapper_1.mapFilterParameters)(request.filters);
  // TODO : Handle Search
  return {
    ...pagination,
    ...sort,
    ...filters
  };
}