import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@werfy/data/authentication';
import { catchError, Observable, switchMap, throwError } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
  ) {}

  public intercept(request: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
    return handler.handle(request).pipe(
      catchError((error: unknown): Observable<HttpEvent<any>> => {
        if ((error as any).status !== HttpStatusCode.Unauthorized) {
          return throwError(() => error);
        }

        if (request.url.endsWith('admin/authentication/sign-in')) {
          return throwError(() => error);
        }

        if (request.url.endsWith('admin/authentication/refresh')) {
          return this.handleSessionEnd$(error);
        }

        const authenticationService: AuthenticationService = this.injector.get(AuthenticationService);
        return authenticationService.refreshSession$().pipe(
          catchError((error: unknown) => this.handleSessionEnd$(error)),
          switchMap(() => handler.handle(request)),
        );
      }),
    );
  }

  private handleSessionEnd$(error: unknown): Observable<never> {
    this.router.navigate(['/'], { onSameUrlNavigation: 'reload' }).then();
    return throwError(() => error);
  }
}
