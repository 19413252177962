import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  State,
  StateType,
  UiBar,
  UiButton,
  UiField,
  UiForm,
  UiLabel,
  UiModal,
  UiSelectInput,
  UiSelectOption,
  UiTextInput,
} from '@standardkit/ui';
import { OrganizationService } from '@werfy/data/organization';

@Component({
  selector: 'wa-invite-modal',
  templateUrl: 'invite.modal.html',
  imports: [
    UiButton,
    UiForm,
    ReactiveFormsModule,
    UiModal,
    UiField,
    UiLabel,
    UiTextInput,
    UiSelectInput,
    UiSelectOption,
    UiBar,
  ],
})
export class InviteModal {
  @Input({ required: true }) public organizationId!: string;

  @Output() public closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() public userInvited: EventEmitter<void> = new EventEmitter<void>();

  public form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required]),
    role: new FormControl(null, [Validators.required]),
  });

  public state: StateType = State.Initial;

  constructor(private organizationService: OrganizationService) {}

  public onClose(): void {
    this.closeModal.emit();
  }

  public onSubmit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.state = State.Pending;
    this.organizationService.invite$(this.organizationId, this.form.value).subscribe({
      next: () => {
        this.state = State.Success;
        this.userInvited.emit();
      },
      error: () => {
        this.state = State.Error;
      },
    });
  }
}
