import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, AuthenticationStore } from '@werfy/core';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { LoginRequest } from './login.request';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private _isLoggedIn: boolean = false;

  constructor(
    private apiService: ApiService,
    private authenticationStore: AuthenticationStore,
  ) {
    this._isLoggedIn = authenticationStore.isLoggedIn();
  }

  public isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  public login$(request: LoginRequest): Observable<void> {
    const url = `admin/authentication/sign-in`;

    return this.apiService.post$<void>(url, request).pipe(tap((): void => this.setLoggedIn(true)));
  }

  // TODO : Implement
  public logout$(): Observable<void> {
    this._isLoggedIn = false;
    return of(undefined);
  }

  public refreshSession$(): Observable<void> {
    const url = 'admin/authentication/refresh';

    return this.apiService.post$<void>(url).pipe(
      catchError((error: unknown): Observable<void> => {
        if ((error as any).status === HttpStatusCode.Unauthorized) {
          this.setLoggedIn(false);
        }
        return throwError(() => error);
      }),
    );
  }

  private setLoggedIn(isLoggedIn: boolean): void {
    this._isLoggedIn = isLoggedIn;
    this.authenticationStore.setLoggedIn(isLoggedIn);
  }
}
