<ui-modal (closeModal)="onClose()" [containsOverflow]="true" name="Invite Member">
  <ui-form [formGroup]="form">
    <ui-field name="email">
      <ui-label>Email</ui-label>
      <ui-text-input formControlName="email" placeholder="michael@werfy.io">
        <ng-container slot="error">Email is required</ng-container>
      </ui-text-input>
    </ui-field>

    <ui-field name="role">
      <ui-label>Role</ui-label>
      <ui-select-input formControlName="role" placeholder="Select the role">
        <ui-select-option label="Recruiter" value="recruiter"></ui-select-option>
        <ui-select-option label="Marketeer" value="marketeer"></ui-select-option>
        <ui-select-option label="Lead" value="lead"></ui-select-option>
        <ui-select-option label="Admin" value="admin"></ui-select-option>
        <ui-select-option label="Owner" value="owner"></ui-select-option>

        <ng-container slot="error">Role is required</ng-container>
      </ui-select-input>
    </ui-field>
  </ui-form>

  <ui-bar align="space-between" slot="footer">
    <ui-button (buttonClick)="onClose()" alignIcon="left" icon="angle-left" type="neutral">Cancel</ui-button>
    <ui-button (buttonClick)="onSubmit()" icon="envelope">Send Invite</ui-button>
  </ui-bar>
</ui-modal>
