import { NgIf } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BreadcrumbInterface,
  State,
  StateType,
  UiBreadcrumbs,
  UiCard,
  UiColumn,
  UiField,
  UiLabel,
  UiRow,
  UiTopBar,
} from '@standardkit/ui';
import { Organization, OrganizationService } from '@werfy/data/organization';
import { InviteModal } from '@werfy/modals';
import { PendingInvitesCard } from '../../cards';

@Component({
  imports: [NgIf, InviteModal, PendingInvitesCard, UiTopBar, UiRow, UiColumn, UiCard, UiField, UiLabel, UiBreadcrumbs],
  templateUrl: 'organization.page.html',
})
export class OrganizationPage implements OnInit {
  @ViewChild('pendingInvitesCard') public pendingInvitesCard!: PendingInvitesCard;

  public breadcrumbs: BreadcrumbInterface[] = [
    { name: 'Werfy Admin Portal', route: '/' },
    { name: 'Organizations', route: '/organizations' },
    { name: 'Organization Detail' },
  ];

  public organization?: Organization;
  public state: StateType = State.Initial;
  public showInviteModal: boolean = false;

  private id: string;

  constructor(
    private organizationService: OrganizationService,
    route: ActivatedRoute,
  ) {
    this.id = route.snapshot.params['id'];
  }

  public ngOnInit(): void {
    this.state = State.Pending;
    this.organizationService.getOrganization$(this.id).subscribe({
      next: (organization: Organization): void => {
        this.organization = organization;
        this.state = State.Success;
      },
      error: (): void => {
        this.state = State.Error;
      },
    });
  }

  public onInviteMember(): void {
    this.showInviteModal = true;
  }

  public onCloseInviteModal(): void {
    this.showInviteModal = false;
  }

  public onUserInvited(): void {
    this.pendingInvitesCard.refresh();
    this.showInviteModal = false;
  }
}
