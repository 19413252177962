"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapSortParameters = mapSortParameters;
function mapSortParameters(sort = []) {
  const parameters = {};
  sort?.forEach(sortOption => {
    parameters[`sort[${sortOption.field}]`] = sortOption.order;
  });
  return parameters;
}