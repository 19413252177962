"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapFilterParameters = mapFilterParameters;
const constants_1 = require("../constants");
function mapFilterParameters(filters = []) {
  const parameters = {};
  filters?.forEach(option => {
    const hasOperator = option.operator !== constants_1.FilterOperator.In && option.operator !== constants_1.FilterOperator.Equals;
    const operator = hasOperator ? `[${option.operator}]` : '';
    const key = `filter[${option.field}]${operator}`;
    parameters[key] = Array.isArray(option.value) ? option.value.join(',') : `${option.value}`;
  });
  return parameters;
}