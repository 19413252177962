import { Injectable } from '@angular/core';
import { Store } from './store';

const LOGGED_IN_KEY = 'is-logged-in';

@Injectable({ providedIn: 'root' })
export class AuthenticationStore {
  constructor(private store: Store) {}

  public setLoggedIn(isLoggedIn: boolean): void {
    this.store.set<boolean>(LOGGED_IN_KEY, isLoggedIn);
  }

  public isLoggedIn(): boolean {
    return this.store.get<boolean | undefined>(LOGGED_IN_KEY) === true;
  }
}
