<ui-modal (closeModal)="onClose()" [containsOverflow]="true" name="Add Organization">
  <ui-form [formGroup]="form">
    <ui-field name="name">
      <ui-label>Name</ui-label>
      <ui-text-input formControlName="name">
        <ng-container slot="error">Organization name is required</ng-container>
      </ui-text-input>
    </ui-field>

    <ui-field name="seats">
      <ui-label>Number of seats</ui-label>
      <ui-number-input formControlName="numberOfSeats">
        <ng-container slot="error">Number of seats is required</ng-container>
      </ui-number-input>
    </ui-field>

    <ui-field name="subscription">
      <ui-label>Subscription</ui-label>
      <ui-select-input formControlName="subscription" placeholder="Select the subscription">
        <ui-select-option label="Trial" value="basic"></ui-select-option>
        <ui-select-option label="Basic" value="basic"></ui-select-option>
        <ui-select-option label="Pro" value="pro"></ui-select-option>
        <ui-select-option label="Enterprise" value="enterprise"></ui-select-option>

        <ng-container slot="error">Subscription is required</ng-container>
      </ui-select-input>
    </ui-field>
  </ui-form>

  <ui-bar align="space-between" slot="footer">
    <ui-button (buttonClick)="onClose()" alignIcon="left" icon="angle-left" type="neutral">Cancel</ui-button>
    <ui-button (buttonClick)="onSubmit()" icon="building">Add Organization</ui-button>
  </ui-bar>
</ui-modal>
