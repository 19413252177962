import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LegacyDataRequest as DataRequest, LegacyDataResponse as DataResponse } from '@standardkit/core';
import {
  BreadcrumbInterface,
  ColumnInterface,
  Style,
  StyleType,
  UiBreadcrumbs,
  UiButton,
  UiDataTable,
  UiTopBar,
} from '@standardkit/ui';
import { Organization, OrganizationService, Subscription } from '@werfy/data/organization';
import { AddOrganizationModal } from '@werfy/modals';

@Component({
  imports: [UiButton, AddOrganizationModal, NgIf, UiTopBar, UiDataTable, UiBreadcrumbs],
  templateUrl: 'organizations.page.html',
})
export class OrganizationsPage {
  public breadcrumbs: BreadcrumbInterface[] = [{ name: 'Werfy Admin Portal', route: '/' }, { name: 'Organizations' }];

  public columns: ColumnInterface<Organization>[] = [
    { key: 'name', label: 'Organization' },
    { key: 'numberOfSeats', label: 'Seats' },
    {
      key: 'subscription',
      label: 'Subscription',
      status: (organization: Organization): StyleType => {
        switch (organization.subscription) {
          case Subscription.Trial:
            return Style.Success;
          case Subscription.Basic:
            return Style.Neutral;
          case Subscription.Pro:
            return Style.Primary;
          case Subscription.Enterprise:
            return Style.Special;
          default:
            return Style.Neutral;
        }
      },
    },
  ];

  public showAddOrganizationModal: boolean = false;
  public response?: DataResponse<Organization>;

  private request?: DataRequest<Organization>;

  constructor(
    private organizationService: OrganizationService,
    private router: Router,
  ) {}

  public onRequest(request: DataRequest<Organization>): void {
    this.request = request;
    this.organizationService.getOrganizations$(request).subscribe({
      next: (response: DataResponse<Organization>): void => {
        this.response = response;
      },
      error: (error: unknown): void => {
        console.error(error);
      },
    });
  }

  public onAddOrganization(): void {
    this.showAddOrganizationModal = true;
  }

  public onCloseAddOrganizationModal(): void {
    this.showAddOrganizationModal = false;
  }

  public onOrganizationAdded(): void {
    this.showAddOrganizationModal = false;
    this.onRequest(this.request as DataRequest<Organization>);
  }

  public onSelectOrganization(organization: Organization): void {
    void this.router.navigate(['/', 'organizations', organization.id]);
  }
}
