"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapPaginationParameters = mapPaginationParameters;
exports.mapPagination = mapPagination;
function mapPaginationParameters(offset, limit) {
  return {
    ...(typeof offset === 'number' && {
      offset
    }),
    ...(typeof limit === 'number' && {
      limit
    })
  };
}
/** We indicate unknown totals as -1 */
function mapPagination(pagination, numberOfRows, totalNumberOfRows = -1) {
  let numberOfPages = -1;
  if (totalNumberOfRows > -1) {
    numberOfPages = totalNumberOfRows < pagination.limit ? 1 : totalNumberOfRows % pagination.limit === 0 ? totalNumberOfRows / pagination.limit : Math.floor(totalNumberOfRows / pagination.limit) + 1;
  }
  return {
    ...pagination,
    totalNumberOfRows: totalNumberOfRows,
    numberOfRows: numberOfRows,
    totalNumberOfPages: numberOfPages,
    currentPage: pagination.offset / pagination.limit + 1
  };
}