"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.entries = entries;
exports.fromEntries = fromEntries;
function entries(object) {
  if (!object) return [];
  return Object.entries(object);
}
function fromEntries(array) {
  return Object.fromEntries(array);
}