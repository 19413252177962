import { DataResponse, LegacyDataResponse } from '@standardkit/core';
import { mapLegacyPagination } from './legacy-pagination.mapper';

export function mapLegacyDataResponse<T>(response: DataResponse<T>): LegacyDataResponse<T> {
  return {
    items: response.data,
    filterable: [],
    sortable: [],
    searchable: [],

    pagination: mapLegacyPagination(response.metadata.pagination),
  };
  // TODO : Implement
}
