import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class Store {
  public set<T>(key: string, value: T): void {
    const stringifiedValue = JSON.stringify(value);

    localStorage.setItem(key, stringifiedValue);
  }

  public get<T>(key: string): T | undefined {
    const stringifiedValue = localStorage.getItem(key);

    if (!stringifiedValue) {
      return;
    }

    return JSON.parse(stringifiedValue);
  }

  public clear(key: string): void {
    localStorage.removeItem(key);
  }

  public clearAll(): void {
    localStorage.clear();
  }
}
