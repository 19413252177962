import { DataRequest, QueryParameters } from '@standardkit/core';
import { mapFilters } from './filter.mapper';
import { mapPagination } from './pagination.mapper';
import { mapSort } from './sort.mapper';

export function mapDataRequest<T>(request: DataRequest<T>): QueryParameters {
  const pagination = mapPagination(request.offset, request.limit);
  const sort = mapSort(request.sort);
  const filters = mapFilters(request.filters);

  // TODO : Handle Search

  return { ...pagination, ...sort, ...filters };
}
